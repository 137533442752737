import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from 'axios';
import mainlogo from '..//img/main_logo_interpartner.png';

//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

//Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

//Context
import ApiRootContext from './ApiRootContext';


export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {
            loginClasses: "fa-spin ml-2 d-none",
            loginMessageClasses: "d-none",
            passwordResetMessageClasses: "d-none",
            loginBtnDisabled: false,
            loginResponse: {},
            passwordResetResponse: {},
            userName: "",
            password: "",
            email: "",
            showResetPassowrdModal: false,
            reCAPTCHAToken: ""
        };
        this.Login = this.Login.bind(this);
        this.ResetPassword = this.ResetPassword.bind(this);
    }

    ResetPassowrdModalClose() {
        this.setState({ showResetPassowrdModal: false });
    }

    ResetPassowrdModalOpen() {
        this.setState({ showResetPassowrdModal: true });
    }

    HandleChange(evt) {
        const value = evt.target.value;
        this.setState({ [evt.target.name]: value });
    }

    HandleReCAPTCHAChagne(value) {
        this.setState({ reCAPTCHAToken: value });
    }

    async Login(APIRoot) {
        this.setState({
            loginClasses: "fa-spin ml-2",
            loginBtnDisabled: true,
            redirectURL: ""
        });

        const request = {
            UserName: this.state.userName,
            Password: this.state.password,
            reCAPTCHAToken: this.state.reCAPTCHAToken
        }

        axios.post("api/user/login", request)
            .then(res => {
                if (res.data.isSuccess) {
                    
                    window.sessionStorage.setItem("userID", res.data.data.userID);
                    window.sessionStorage.setItem("userToken", res.data.data.userAccessToken);
                    window.sessionStorage.setItem("isProvider", res.data.data.isProvider);
                    this.setState({
                        loginMessageClasses: "d-none mb- 3",
                        loginResponse: res.data
                    });
                    this.props.authCheck();
                    if (res.data.data.isProvider)
                        this.setState({ redirectURL: '/UnprocessedServices' });
                    else
                        this.setState({ redirectURL: '/InsuranceServices' });
                }
                else {
                    this.setState({
                        loginClasses: "fa-spin ml-2 d-none",
                        loginBtnDisabled: false,
                        loginResponse: res.data,
                        loginMessageClasses: "mb-3"
                    });
                }
            });
    }

    async ResetPassword() {
        const request = {
            UserName: this.state.userName,
            UserEmail: this.state.email,
            reCAPTCHAToken: this.state.reCAPTCHAToken
        }

        axios.post("api/user/resetpassword", request)
            .then(res => {
                this.setState({
                    passwordResetMessageClasses: "mb-3",
                    passwordResetResponse: res.data
                });
            });
    }

    render() {
        if (this.state.redirectURL) {
            return <Redirect to={this.state.redirectURL} />
        }
        return (
            <>
                <Row className="justify-content-center mt-2">
                    <Col md={6}>
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col xs={3}>
                                        <img
                                            alt=""
                                            src={mainlogo}
                                            width="70"
                                            height="70"
                                            className="d-inline-block align-top"
                                        />
                                    </Col>
                                    <Col xs={9}>
                                        <h1 className="mt-2">IPAEksper</h1>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Form id="loginForm" name="loginForm">
                                    <Row className={this.state.loginMessageClasses}>
                                        <Col>
                                            <ListGroup>
                                                <ListGroup.Item variant="primary">
                                                    <span>{this.state.loginResponse.message}</span>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                    <Form.Group as={Row} className="justify-content-center mb-0">
                                        <Col sm="12">
                                            <InputGroup className="mb-0">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text className="rounded-0">
                                                        <FontAwesomeIcon icon={faUser} />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control name="userName" autoComplete="off" className="rounded-0" placeholder="Kullanıcı Adı" onChange={e => this.HandleChange(e)} />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="justify-content-center">
                                        <Col sm="12">
                                            <InputGroup className="mb-0">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text className="rounded-0">
                                                        <FontAwesomeIcon icon={faLock} />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control name="password" className="rounded-0" type="password" placeholder="Şifre" onChange={e => this.HandleChange(e)} />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <ReCAPTCHA className="mb-3" sitekey="6Lcz3YUUAAAAAA7XW9HSm6jUFDoDkGkouBebgDH_" onChange={value => this.HandleReCAPTCHAChagne(value)} />
                                    <ApiRootContext.Consumer>
                                        {(apiRoot) =>
                                            <Button name="btnLogin" id="btnLogin" variant="primary" disabled={this.state.loginBtnDisabled} onClick={() => this.Login({ apiRoot })} >
                                                Giriş Yap
                                        <FontAwesomeIcon className={this.state.loginClasses} icon={faCircleNotch} />
                                            </Button>}
                                    </ApiRootContext.Consumer>
                                    <span> veya </span>
                                    <Button className="p-0 m-0 pb-1" variant="link" onClick={() => this.ResetPassowrdModalOpen()}>şifremi unuttum</Button>
                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                Inter Partner Assistance - <Link to="/privacypolicy">Gizlilik Politikası</Link>
                        </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Modal show={this.state.showResetPassowrdModal} onHide={() => this.ResetPassowrdModalClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Şifre Talebi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={this.state.passwordResetMessageClasses}>
                            <Col>
                                <ListGroup>
                                    <ListGroup.Item variant="primary" >
                                        <span>{this.state.passwordResetResponse.message}</span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Col>
                        </Row>
                        <Form.Group as={Row} className="justify-content-center mb-0">
                            <Col sm="12">
                                <InputGroup className="mb-0">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="rounded-0">
                                            <FontAwesomeIcon icon={faUser} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control name="userName" className="rounded-0" placeholder="Kullanıcı Adı" onChange={e => this.HandleChange(e)} />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="justify-content-center">
                            <Col sm="12">
                                <InputGroup className="mb-0">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className="rounded-0">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control name="email" className="rounded-0" placeholder="Email Adresi" onChange={e => this.HandleChange(e)} />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <ReCAPTCHA className="mb-3" sitekey="6Lcz3YUUAAAAAA7XW9HSm6jUFDoDkGkouBebgDH_" onChange={value => this.HandleReCAPTCHAChagne(value)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.ResetPassowrdModalClose()}>
                            Kapat
                    </Button>
                        <Button variant="primary" onClick={() => this.ResetPassword()}>
                            Şifre Yenile
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
