import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

//Layouts
import LayoutRoute from './components/Layout';
import LoginLayoutRoute from './components/LoginLayout';

//Components
import { UnprocessedServices } from './components/UnprocessedServices';
import { Login } from './components/Login';
import { RAXAInventory } from './components/RAXAInventory';
import { RAXADelivery } from './components/RAXADelivery';
import { Invoicing } from './components/Invoicing';
import { InsuranceServices } from './components/InsuranceServices';
import { RequestedCases } from './components/RequestedCases';
import { NewCaseRequest } from './components/NewCaseRequest';
import { HomeRepairCases } from './components/HomeRepairCases';
import { Pricing } from './components/Pricing';
import { Automission } from './components/Automission'
import { UserSettings } from './components/UserSettings'
import { RepairmentsPrint } from './components/RepairmentsPrint';
import { PayBack } from './components/PayBack';
import { AuthorityForm } from './components/AuthorityFrom';
import { CookiePolicy } from './components/CookiePolicy';
import { PrivacyPolicy } from './components/PrivacyPolicy';

import './custom.css'
import 'react-notifications/lib/notifications.css';
import { ServicePhoto } from './components/ServicePhoto';



class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props)
        this.state = {
            
        }

        this.autheCheck = this.autheCheck.bind(this);
    }

    autheCheck() {
        this.setState({ authCheck: true });
    }

    render() {
        const api_regex = /^\/api\/.*/;
        const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
        if (api_regex.test(window.location.pathname)) {
            return <div />
        } else {
            return (
                <Router basename={baseUrl}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/login" />
                        </Route>
                        <Route path="/RAXADelivery/:serviceid/:accesstoken" render={props => (
                            <LoginLayoutRoute {...props} component={RAXADelivery} />
                        )} />
                        <Route path="/Automission/:serviceid/:automissioncode" render={props => (
                            <LoginLayoutRoute {...props} component={Automission} />
                        )} />
                        <Route path="/geriodeme" render={props => (
                            <LoginLayoutRoute {...props} component={PayBack} />
                        )} />
                        <Route path="/yetkiformu" render={props => (
                            <LoginLayoutRoute {...props} component={AuthorityForm} />
                        )} />
                        <Route path="/privacypolicy" render={props => (
                            <LoginLayoutRoute {...props} component={PrivacyPolicy} />
                        )} />
                        <Route path="/cookies" render={props => (
                            <LoginLayoutRoute {...props} component={CookiePolicy} />
                        )} />
                        <LoginLayoutRoute path="/login" component={Login} authCheck={this.autheCheck} />
                        <LayoutRoute path="/UserSettings" component={UserSettings} />
                        <LoginLayoutRoute path="/homeRepairCaseRepairments/:caseCode" component={RepairmentsPrint} />
                        <LoginLayoutRoute path="/ipaeksperPhoto/:id/:serviceID" component={ServicePhoto} />

                        {/*window.sessionStorage.getItem("isProvider") == "true" && <LayoutRoute path="/home" component={FetchData} />*/}
                        {window.sessionStorage.getItem("isProvider") === "true" && <LayoutRoute path="/UnprocessedServices" component={UnprocessedServices} />}
                        {window.sessionStorage.getItem("isProvider") === "true" && <LayoutRoute path="/RAXAInventory" component={RAXAInventory} />}
                        {window.sessionStorage.getItem("isProvider") === "true" && <LayoutRoute path="/Invoicing" component={Invoicing} />}
                        {window.sessionStorage.getItem("isProvider") === "true" && <LayoutRoute path="/HomeRepairCases" component={HomeRepairCases} />}
                        {window.sessionStorage.getItem("isProvider") === "true" && <LoginLayoutRoute path="/Pricing" component={Pricing} />}
                        {window.sessionStorage.getItem("isProvider") === "false" && <LayoutRoute path="/InsuranceServices" component={InsuranceServices} />}
                        {window.sessionStorage.getItem("isProvider") === "false" && <LayoutRoute path="/RequestedCases" component={RequestedCases} />}
                        {window.sessionStorage.getItem("isProvider") === "false" && <LayoutRoute path="/NewCaseRequest" component={NewCaseRequest} />}

                    </Switch>
                </Router>
            );
        }
    }
}

export default App;
